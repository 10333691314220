import moment from "moment";
import React, { Fragment } from "react";

// Icons
import { AiOutlinePhone } from "@react-icons/all-files/ai/AiOutlinePhone";

// Components
import { Heading, HeroImage, HorizontalDividerCard } from "@components";

// Images
import ServicePlaceholder from "@images/placeholder.png";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import { ContactForm } from "@components";

const ServiceDetails = ({ pageContext }) => {
    // const { name, phone, image, description, openingHours } = pageContext;
    const openingHours = [
        {
            id: 1,
            name: "Monday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 17, 0),
        },
        {
            id: 2,
            name: "Tuesday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 17, 0),
        },
        {
            id: 3,
            name: "Wednesday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 17, 0),
        },
        {
            id: 4,
            name: "Thursday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 17, 0),
        },
        {
            id: 5,
            name: "Friday",
            opening: new Date(2023, 12, 12, 9, 0),
            closing: new Date(2023, 12, 12, 17, 0),
        },
    ];

    const { placeholder } = useStaticQuery(
        graphql`
            query {
                placeholder: file(relativePath: { eq: "pages/dentists.jpeg" }) {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    return (
        <>
            <HeroImage
                title={"Dentist"}
                image={placeholder?.childImageSharp?.fluid}
            />

            <div className="max-w-1280 mx-auto py-5 px-3 xl:px-0 flex flex-col md:flex-row justify-between gap-x-8">
                <div className="pt-3 w-full md:w-3/5">
                    <p className="mb-4">
                        Here at Broad Oak Medical, Dental and Specialist Centre
                        we have an experienced and compassionate dental team.
                    </p>

                    <p className="mb-2">Our Dentists offer services such as:</p>

                    <ul className="list-disc pl-3">
                        <li>Dental check-ups</li>
                        <li>Teeth cleaning</li>
                        <li>Orthodontics</li>
                        <li>Preventative care</li>
                        <li>Dental x-rays and OPGs</li>
                        <li>Extractions</li>
                        <li>Root Canals</li>
                        <li>
                            Cosmetic dentistry; bridges, crowns, and veneers
                        </li>
                    </ul>

                    <p className="mt-8">
                        To provide accessible and affordable dental care, our
                        dentists offer payment plans through Afterpay and Zip
                        Pay, as well as accept Monash Vouchers and see children
                        who are eligible to be billed under the Children Dental
                        Benefit Schedule (CDBS).
                    </p>

                    <p className="mt-8">
                        We are preferred providers for HCF and NIB, meaning if
                        you hold a valid HCF or NIB health insurance card we
                        offer a no gap payment for check-up, cleans and x-rays.
                    </p>

                    <p className="mt-8">
                        For any enquiries or to make an appointment, please
                        contact our friendly reception team on 5995 1000.
                        Alternatively, you can fill in the form below and we
                        will be in touch with you.
                    </p>

                    <div className="w-full mt-4">
                        <StaticImage
                            src={"../../images/pages/dentists.jpeg"}
                            alt={"Doctors Image"}
                            className="w-full h-52 object-cover rounded-xl shadow"
                        />
                    </div>
                </div>
                <div className="w-full md:w-2/5 pt-3">
                    <Heading size={"xl"}>Opening hour</Heading>
                    {openingHours && openingHours.length > 0 ? (
                        openingHours?.map(
                            ({ id, name, opening, closing }, i) => (
                                <Fragment key={id}>
                                    <div className="w-full flex justify-between items-center py-1">
                                        <h4 className="text-black text-sm  font-normal mb-0">
                                            {name}
                                        </h4>
                                        <p className="text-black text-sm  font-semibold mb-0">
                                            {moment(opening, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}{" "}
                                            -{" "}
                                            {moment(closing, "hh:mm:ss").format(
                                                "h:mm a"
                                            )}
                                        </p>
                                    </div>
                                    {i !== openingHours.length - 1 && (
                                        <HorizontalDividerCard
                                            colors={[
                                                "#FFFFFF00",
                                                "#7B61FF",
                                                "#FFFFFF00",
                                            ]}
                                        />
                                    )}
                                </Fragment>
                            )
                        )
                    ) : (
                        <div className="mt-10">
                            <AiOutlinePhone className="text-gray77 text-3xl mx-auto" />
                            <p className="text-base text-gray77 text-center  mt-2.5">
                                Please call for appointments
                            </p>
                        </div>
                    )}

                    <div className="border mt-4 p-2">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceDetails;
